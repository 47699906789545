import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import RoundedButton from '../components/buttons/roundedButton';
import { CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, media } from '../constants/screens';
import { BLUE, WHITE } from '../constants/colors';
import { track404Page } from '../utils/analytics';

/**
 * Please note that in a develop mode gatsby shows only default 404 page
 * To check this component you should go direct to /404 route
 * */

class NotFoundPage extends React.Component {
  componentDidMount() {
    const currentLocation = typeof document === 'object' ? document.location.href : null;
    const isNot404ClientSideRedirect = currentLocation && currentLocation.split('/')[3] !== '404';
    if (isNot404ClientSideRedirect) { track404Page(currentLocation); }
  }

  render() {
    const { data } = this.props;

    return (
      <Container>
        <MainContent>
          <ImageWrapper>
            <Image sizes={data.image404.childImageSharp.sizes} />
          </ImageWrapper>
          <StyledLink to="/">
            <RoundedButton
              text="back to home"
              color={BLUE}
              colorWithoutHover={WHITE}
              background={BLUE}
              withoutHover
            />
          </StyledLink>
        </MainContent>
      </Container>
    );
  }
}

NotFoundPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 5%;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  justify-content: center;
  align-items: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;

const ImageWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin-bottom: 110px;
  
  > div {
    width: 100%;
  }
`;

export default NotFoundPage;

// eslint-disable-next-line
export const query = graphql`
  query NotFoundPageQuery {
    image404: file(relativePath: { regex: "/404/" })  {
      childImageSharp {
        sizes(maxWidth: 800 ) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
  }
`;
